/* eslint-disable */
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
// import { Route, Switch } from 'react-router-dom';
import Header from "#components/Header";
import Footer from "#components/Footer";
import { get } from "#utils/globalVariable";

const Wrapper = styled.div`
  height: 100%;
  min-height: 598px;
`;

const HeaderWrapper = styled.div`
  height: 32px;
  margin: 32px;
  @media (max-width: 370px) {
    margin: 32px 7vw;
  }
`;

const ContentWrapper = styled.div`
  /* display: flex; */
  display: ${({ env }) => (env === "MOBILE" ? "block" : "flex")};
  flex-direction: column;
  justify-content: ${({ env }) => (env === "MOBILE" ? "start" : "center")};
  align-items: center;
  box-sizing: border-box;
  width: 56%;
  height: ${({ showHeader, showFooter }) =>
    // eslint-disable-next-line no-nested-ternary
    showFooter
      ? showHeader
        ? "calc(100% - 196px)"
        : "100%"
      : "100%"};
  max-width: 450px;
  min-width: 288px;
  min-height: 575px;
  margin: 0 auto;
  padding: ${({ env }) => (env === "MOBILE" ? "calc(21px + 3.6%) 0 0" : "0")};
  @media (max-width: 832px) {
    width: 82%;
    max-width: 82%;
  }
`;

const Content = styled.div`
  width: 100%;
  height: ${({ env }) => (env === "MOBILE" ? "calc(100% - 16px)" : "auto")};
  margin-bottom: ${({ env, showHeader }) =>
    // eslint-disable-next-line no-nested-ternary
    // env === "MOBILE" ? "16px" : showHeader ? "300px" : 0};
    env === "MOBILE" ? "16px" : showHeader ? "178.5px" : 0};
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  // height: 100px;
  max-width: 450px;
  margin: 0 auto;
  margin-top: 50px;
`;

const Center = ({ showHeader, renderHeader, showFooter, children }) => {
  const env = get("env");
  const headerIsShown = env === "WEB" && showHeader;
  const footerIsShown = env === "WEB" && showFooter;
  return (
    <Wrapper>
      {headerIsShown && (
        <HeaderWrapper>
          {renderHeader ? renderHeader() : <Header />}
        </HeaderWrapper>
      )}
      <ContentWrapper
        env={env}
        showHeader={headerIsShown}
        showFooter={footerIsShown}
      >
        <Content env={env} showHeader={headerIsShown}>
          {children}
          {
            footerIsShown && (
              <FooterWrapper>
                <Footer />
              </FooterWrapper>
            )
          }
        </Content>
      </ContentWrapper>
      {// env === 'WEB' && (
      //   <Switch>
      //     <Route path="/auc/password-forget" exact component={null} />
      //     <Route
      //       render={
      //         () => (
      //           <FooterWrapper>
      //             <Footer />
      //           </FooterWrapper>
      //         )
      //       }
      //     />
      //   </Switch>
      // )
        // footerIsShown && (
        //   <FooterWrapper>
        //     <Footer />
        //   </FooterWrapper>
        // )
      }
    </Wrapper>
  );
};

Center.propTypes = {
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool,
  renderHeader: PropTypes.func
};

Center.defaultProps = {
  showHeader: false,
  showFooter: false,
  renderHeader: null
};

export default Center;
