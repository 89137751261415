import MsgModal from '#components/MsgModal';
import { defineMessages } from '#utils/i18n/intl';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import styled from 'styled-components';

const HelpTipWrapper = styled.div`
margin: 16px 0 0;
text-align: center;
`;

const HelpTipFirst = styled.span`
font-size: 12px;
color: #aaaaaa;
`;

const HelpTipSecond = styled.span`
font-size: 12px;
color: #0564f5;
cursor: pointer;
`;

const HelpMsgWrapper = styled.div`
text-align: justify;
`;

const HelpMsgTitle = styled.h4`
color: #525252;
word-break: break-all;
`;

const HelpMsgLine = styled.p`
font-size: 12px;
word-break: break-all;
`;

const messages = defineMessages({
  unableToLogin: {
    id: 'TOTPBind.unableToLogin',
    defaultMessage: '没有收到验证码？',
  },
  askForHelp: {
    id: 'TOTPBind.askForHelp',
    defaultMessage: '查看帮助',
  },
  helpMsgTitle_1: {
    id: 'TOTPBind.helpMsgTitle_1',
    defaultMessage: '如何获取身份认证器？',
  },
  helpMsgTitle_2: {
    id: 'TOTPBind.helpMsgTitle_2',
    defaultMessage: '如果身份认证器不小心卸载或者手机更换/丢失，如何进行登录？',
  },
  helpMsgLine_1: {
    id: 'TOTPBind.helpMsgLine_1',
    defaultMessage: '如您企业内部没有推荐使用的身份认证器，可以通过手机官方应用市场搜索“Authenticator”，选择可靠的身份认证器进行使用，推荐使用“MIcrosoft Authenticator”或者“腾讯身份验证器”；或者在微信中搜索“腾讯身份验证器”小程序进行使用。',
  },
  helpMsgLine_2: {
    id: 'TOTPBind.helpMsgLine_2',
    defaultMessage: '绝大部分身份认证器应用都支持云端备份，建议开启备份，避免因应用卸载或者设备丢失后，无法获取TOTP令牌。',
  },
  helpMsgLine_3: {
    id: 'TOTPBind.helpMsgLine_3',
    defaultMessage: '如果您之前使用的身份认证器启用了云端备份，应用重新安装并同步后，即可正常获取TOTP令牌。',
  },
  helpMsgLine_4: {
    id: 'TOTPBind.helpMsgLine_4',
    defaultMessage: '如果您之前未启用云端备份，请联系您的租户管理员帮您解绑之前的身份认证器绑定信息，解绑后再次登录可重新进行绑定。',
  },
});

const TOTPShowHelpMsg = ({
  onOpenHelpModal,
  onCloseHelpModal,
  showHelpMsg,
}) => {
  const HelpMsg = (
    <HelpMsgWrapper>
      <HelpMsgTitle>
        {intl.formatMessage(messages.helpMsgTitle_1)}
      </HelpMsgTitle>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_1)}
      </HelpMsgLine>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_2)}
      </HelpMsgLine>
      <HelpMsgTitle>
        {intl.formatMessage(messages.helpMsgTitle_2)}
      </HelpMsgTitle>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_3)}
      </HelpMsgLine>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_4)}
      </HelpMsgLine>
    </HelpMsgWrapper>
  );


  return (
    <>
      <HelpTipWrapper>
        <HelpTipFirst>
          {intl.formatMessage(messages.unableToLogin)}
        </HelpTipFirst>
        <HelpTipSecond onClick={onOpenHelpModal}>
          {intl.formatMessage(messages.askForHelp)}
        </HelpTipSecond>
      </HelpTipWrapper>
      <MsgModal
        show={showHelpMsg}
        msg={HelpMsg}
        onClose={onCloseHelpModal}
      />
    </>
  );
};

TOTPShowHelpMsg.propTypes = {
  onOpenHelpModal: PropTypes.func.isRequired,
  onCloseHelpModal: PropTypes.func.isRequired,
  showHelpMsg: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    // showVerificationCodeHelpMessage: state.showVerificationCodeHelpMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // onOpenHelpModal() {
    //   dispatch(jumpToVerificationCodeHelpMessage(true));
    // },
    // onCloseHelpModal() {
    //   dispatch(jumpToVerificationCodeHelpMessage(false));
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TOTPShowHelpMsg);
