/* eslint-disable */
import { call, put } from "redux-saga/effects";
import { store } from "../index";
import fetchTenant from "./fetchTenant";
import {
  selectTenant,
  verifyCodeError,
  setLoginLoading,
  setAuthTypeInfo,
} from "../action";
import { jumpAuthentication, getAuthenticationType, jumpToTOTP } from "../LoginService"

export default function* authentication(context, action) {
  console.log(action, 'action.payload')
  const id = action.payload;
  const { tenantList, username, passport } = store.getState();
  console.log(tenantList, 'tenantList认证文件中的值');
  const { mfa_status } = tenantList.byId[
    id
  ];
  // 选择租户
  yield put(selectTenant(id));
  yield put(verifyCodeError(null));
  if(mfa_status === '1') {
    // const tenantDetail = getTenantDetail(tenantList, id)
    const res = yield call(getAuthenticationType, {
      loginName: username,
      passportId: passport,
      // tenantId: tenantDetail && tenantDetail.tenantId,
      tenantId: id,
    })
    if (res.scode == 200) {
      // 获取判断是哪种双认证接口 mfaType 0 帐号验证码  3 TOTP验证码
      yield put(setAuthTypeInfo(res.result));
      // 根据条件判断执行方法
      yield put(setLoginLoading(false));
      if(res.result.mfaType == 0) {
        yield call(jumpAuthentication, context);
      }else if (res.result.mfaType == 3){
        yield call(jumpToTOTP, context);
      }
    } else {
      // 接口调用失败
      yield put(setLoginLoading(false));
      console.log(res.error_msg)
      return
    }
  } else {
    yield call(fetchTenant, action);
  }
}
